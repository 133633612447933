
//mutations 操作类型常量
export const ASSET_MUTATIONS ={
    //资产页设置是否展示资产
    SETSMALLHIDEORSHOWASSET:"updateHideAsset",
    //更新是否展示 隐藏 资产
    UPDATESHOWASSET:"updateShowAsset",
    // 资产页 总资产展示币种 BTC USDT 
    UPDATEVALUATIONUNIT:"updateValuationUnit"
}





