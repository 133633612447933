
// mutaions
import { localHideSmallAsset } from '~/config/global.config.js'

import LocalCookie , { CookieKey} from '~/utils/localCookie.js'
import {
  ASSET_MUTATIONS
} from './types'


let mutaions ={
    /**
     * @name 是否隐藏小额资产
     * @param {*} state 
     */
    [ASSET_MUTATIONS.SETSMALLHIDEORSHOWASSET](state,val){
        if(val){
            localStorage.setItem(localHideSmallAsset,val);
          } else {
            localStorage.removeItem(localHideSmallAsset);
          }
          state.hideSmallAsset=val;
    },
    /**
     * @name 是否展示资产
     * @param {boolean} val
     */
    [ASSET_MUTATIONS.UPDATESHOWASSET](state,val){
      state.showAsset=val;
      if(process.browser){
        LocalCookie.set(CookieKey.SHOWASSET,val);
      }

    },
    /**
     * @name 设置资产展示类型 1 BTC 2 USDT 
     * @param {*} state 
     * @param {Boolean} val 
     */
    [ASSET_MUTATIONS.UPDATEVALUATIONUNIT](state,val){
      state.valuationUnitType=val;
      if(process.browser){
        LocalCookie.set(CookieKey.VALUATIONUNIT,val);
      }

    }
}

export default  mutaions
