
/**
 * 获取配置信息
 * 隐藏小额资产
 * 是否展示资产
 * 本地单位切换
 */



import mutations from './mutations'
import actions from './actions'
import getters from './getters'


import { accountList } from '~/server/financial/'

import LocalCookie , { CookieKey} from '~/utils/localCookie.js'


const  state =()=>({
  //是否隐藏小额资产
    hideSmallAsset: false, 
    //展示资产
    showAsset:true,
    //资产展示单位 1 BTC 2 USDT
    valuationUnitType:1, 
    /**资产中所有的币种列表 
    *{ coinId coinName fCoinUrl }  
    * 为了记录 币种的 图标资源  
    */
    allAssetCoinList:[],
    //可划转的币种列表
    transferCoinList:[],
})

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

