// @flow
import type {GetterTree} from 'vuex';
import type {IState} from './types';
import {GETTER_TYPES} from './types';
import {calMaxOpenContractCount} from '~/components/swap/src/Calculator';
import {Decimal} from 'decimal.js';

const getters: GetterTree<IState, any> = {
  /**
   * 根据持仓张数获取维持保证金率
   * @param state
   * @return {function(...[*]=)}
   */
  [GETTER_TYPES.KEEP_MARGIN_RATE](state) {
    const {positionLeverageLevel, positionLeverageLevelAll} = state;
    return (holdCount: number, productCode: string) => {
      let temp;
      if (productCode) {
        let data = positionLeverageLevelAll.find(item => item.productCode === productCode);
        if (data && data.list && data.list.length > 0) {
          temp = data.list.find(level => (holdCount >= level.startUnit && holdCount <= level.endUnit));
        }
      } else {
        temp = positionLeverageLevel.find(level => (holdCount >= level.startUnit && holdCount <= level.endUnit));
      }
      return temp ? temp.keepMarginRate : 0;
    };
  },
  /**
   * 根据杠杆倍数获取对应档位最大的持仓限制
   * @param state
   */
  [GETTER_TYPES.LEVERAGE_MAX_HOLD](state) {
    const {positionLeverageLevel, positionLeverageLevelAll} = state;
    return (leverage: number, productCode: string) => {
      let temp;
      if (productCode) {
        let data = positionLeverageLevelAll.find(item => item.productCode === productCode);
        if (data && data.list && data.list.length > 0) {
          temp = data.list.reverse().find(level => leverage <= level.leverage);
        }
      } else {
        // 由于要寻找杠杆最大的可开，要把仓位档位梯度倒序寻找
        temp = [...positionLeverageLevel].reverse().find(level => leverage <= level.leverage);
      }
      return temp ? temp.endUnit : 0;
    };
  },
  /**
   * 根据当前持仓，得到最大可调整的杠杆
   * @param state
   */
  [GETTER_TYPES.HOLD_MAX_LEVERAGE](state) {
    const {positionLeverageLevel, positionLeverageLevelAll} = state;
    return (hold: number, productCode: string) => {
      let temp;
      if (productCode) {
        let data = positionLeverageLevelAll.find(item => item.productCode === productCode);
        if (data && data.list && data.list.length > 0) {
          temp = data.list.reverse().find(level => (hold <= level.endUnit && hold >= level.startUnit));
        }
      } else {
        temp = [...positionLeverageLevel].reverse().find(level => (hold <= level.endUnit && hold >= level.startUnit));
      }
      return temp ? temp.leverage : 0;
    };
  },
  /**
   * 使用可用计算最大可开张数（始终返回张数）
   * @param state
   * @param getters
   * @return {function(...[*]=)}
   */
  [GETTER_TYPES.CAN_MAX_OPEN](state, getters) {
    const {
      assets,
    } = state;
    return (
      calPrice: number | string,
      type: number,
      changedLeverage: string,
    ) => {
      return getters[GETTER_TYPES._CAN_MAX_OPEN](assets.balanceText, calPrice,
        type, changedLeverage);
    };
  },
  /**
   * 使用任意资产量计算最大可开张数（始终返回张数）
   * @param state
   * @param getters
   * @private
   */
  [GETTER_TYPES._CAN_MAX_OPEN](state, getters) {
    const {
      longPositionInfo,
      shortPositionInfo,
      contractInfo,
      assets,
      positionAll
    } = state;
    return (
      balance: number | string,
      calPrice: number | string,
      type: number,
      changedLeverage: string,
    ) => {
      // type =》 PositionInfo.position
      
      let leverage = assets.longLeverage,
        holdCount = longPositionInfo.holdCount,
        delegateCount = assets.longAccountDelegateCount;
      if (type === 2) {
        leverage = assets.shortLeverage;
        holdCount = shortPositionInfo.holdCount;
        delegateCount = assets.shortAccountDelegateCount;
      }
      //支援分倉下取已開倉位數量 
      if(contractInfo.splitMargin && positionAll.length!=0){
        holdCount = 0;
        positionAll.forEach(item => {
          if(assets.isFixedMode) { //逐倉已開倉位數量分開計算
            if(item.position==type) { //type:1(多), type:2(空)
              holdCount += Number(item.amount);
            }
          }else { //全倉模式已開倉位多空合併一起計算
            holdCount += Number(item.amount);
          }
        });
      }
      
      // 全仓模式，多空一起算
      if (!assets.isFixedMode) {
        delegateCount =
          Number(assets.longAccountDelegateCount) +
          Number(assets.shortAccountDelegateCount);
        if(!contractInfo.splitMargin){ //不支援合倉的已開倉位數量
          holdCount = longPositionInfo.holdCount + shortPositionInfo.holdCount;
        }
      }
      let endUnit = getters[GETTER_TYPES.LEVERAGE_MAX_HOLD](changedLeverage || leverage);

      let result = calMaxOpenContractCount(
        new Decimal(balance),
        new Decimal(calPrice),
        new Decimal(contractInfo.feeRate),
        new Decimal(contractInfo.openCostUpRatio),
        new Decimal(contractInfo.openCostFeeUpRatio),
        new Decimal(changedLeverage || leverage),
        new Decimal(contractInfo.preValue),
        new Decimal(endUnit),
        new Decimal(holdCount),
        new Decimal(delegateCount),
        !contractInfo.forwardContractFlag,
      );

      return result.toString();
    };
  },

  /**
   * 使用可用计算最大可开张数（始终返回张数） - 选中
   * @param state
   * @param getters
   * @return {function(...[*]=)}
   */
  [GETTER_TYPES.SEL_CAN_MAX_OPEN](state, getters) {
    const { selAssets } = state;
    return (
      calPrice: number | string,
      type: number,
      changedLeverage: string,
    ) => {
      return getters[GETTER_TYPES._CAN_MAX_OPEN](selAssets.balanceText, calPrice, type, changedLeverage);
    };
  },
  /**
   * 使用任意资产量计算最大可开张数（始终返回张数） - 选中
   * @param state
   * @param getters
   * @private
   */
  [GETTER_TYPES._SEL_CAN_MAX_OPEN](state, getters) {
    const {
      selLongPositionInfo,
      selShortPositionInfo,
      selContractInfo,
      selAssets,
    } = state;
    return (
      balance: number | string,
      calPrice: number | string,
      type: number,
      changedLeverage: string,
    ) => {
      // type =》 PositionInfo.position
      let leverage = selAssets.longLeverage,
        holdCount = selLongPositionInfo.holdCount,
        delegateCount = selAssets.longAccountDelegateCount;
      if (type === 2) {
        leverage = selAssets.shortLeverage;
        holdCount = selShortPositionInfo.holdCount;
        delegateCount = selAssets.shortAccountDelegateCount;
      }

      // 全仓模式下
      if (!selAssets.isFixedMode) {
        holdCount = selLongPositionInfo.holdCount + selShortPositionInfo.holdCount;
        delegateCount =
          Number(selAssets.longAccountDelegateCount) +
          Number(selAssets.shortAccountDelegateCount);
      }

      // 全仓模式，多空一起算
      if (!selAssets.isFixedMode) {
        delegateCount =
          Number(selAssets.longAccountDelegateCount) +
          Number(selAssets.shortAccountDelegateCount);
        holdCount = selLongPositionInfo.holdCount + selShortPositionInfo.holdCount;
      }

      let endUnit = getters[GETTER_TYPES.LEVERAGE_MAX_HOLD](leverage);

      let result = calMaxOpenContractCount(
        new Decimal(balance),
        new Decimal(calPrice),
        new Decimal(selContractInfo.feeRate),
        new Decimal(selContractInfo.openCostUpRatio),
        new Decimal(selContractInfo.openCostFeeUpRatio),
        new Decimal(changedLeverage || leverage),
        new Decimal(selContractInfo.preValue),
        new Decimal(endUnit),
        new Decimal(holdCount),
        new Decimal(delegateCount),
        !selContractInfo.forwardContractFlag,
      );

      return result.toString();
    };
  },

  /**
   * 用于按照step设置格式化输入价格
   * @param state
   * @returns {function(*=, *=): string}
   */
  [GETTER_TYPES.STEPPED_PRICE](state) {
    const {contractPlaceInfo, contractInfo} = state;
    return (price, needUp = false) => {
      let inputPrice = new Decimal(price),
        littlePart = inputPrice.toDP(
          Number(contractPlaceInfo.delegatePricePlace) - 1,
          Decimal.ROUND_FLOOR),
        formatPart = inputPrice.toDP(contractPlaceInfo.delegatePricePlace,
          Decimal.ROUND_DOWN),
        stepPart = formatPart.sub(littlePart).
          div(contractInfo.priceEndStep).
          toDP(contractPlaceInfo.delegatePricePlace,
            needUp ? Decimal.ROUND_UP : Decimal.ROUND_DOWN).
          times(contractInfo.priceEndStep).
          toDP(contractPlaceInfo.delegatePricePlace);
      return littlePart.add(stepPart).
        toFixed(contractPlaceInfo.delegatePricePlace);
    };
  },
  /**
   * 校验输入价格并给出格式化建议
   * @param state
   * @param getters
   */
  [GETTER_TYPES.CHECK_INPUT_PRICE](state, getters) {
    const {contractPlaceInfo, contractInfo} = state;
    /**
     *  price: 输入价格
     *  needUp: 是否在step时向上取整
     *  extraRule: 额外的校验条件
     */
    return (price, needUp = false, extraRule?: Function) => {
      if (typeof extraRule !== 'function') {
        extraRule = () => true;
      }
      return new Promise((resolve, reject) => {
        // 格式不合法reject
        let reg = new RegExp(`^(0|[1-9]\\d*)(\\.\\d{0,${contractPlaceInfo.delegatePricePlace}})?$`);

        if (reg.test(price) && extraRule(price)) {
          // 检查是否符合step要求
          let calStep = Number(contractInfo.priceEndStep) *
            Number('1e-' + String(contractPlaceInfo.delegatePricePlace));

          // 如果本身符合step就不用过度补全step信息，比如整数就不用格式化出小数点
          if (new Decimal(price).mod(new Decimal(calStep)).isZero()) {
            resolve(price);
          } else {
            resolve(getters[GETTER_TYPES.STEPPED_PRICE](price, needUp));
          }
        } else {
          reject();
        }
      });
    };
  },
  /**
   * 张数转币量
   * @param state
   */
  [GETTER_TYPES.TRANSFER_COUNT_TO_AMOUNT](state) {
    const {contractPlaceInfo, contractInfo, unitType} = state;
    /**
     * 张数=>币量
     * 正向合约：inputCount(张) * PreValue(BaseSymbol/张) = N BaseSymbol
     * 反向合约：(inputCount(张) * PreValue(PricedSymbol/张)) / inputPrice(PricedSymbol/BaseSymbol) = N BaseSymbol
     */
    return (inputCount, inputPrice) => {
      let dCount = new Decimal(isNaN(inputCount) ? 0 : inputCount);
      let dPrice = new Decimal(isNaN(inputPrice) ? 0 : inputPrice);
      let isForward = contractInfo.forwardContractFlag;
      let result = dCount.times(contractInfo.preValue);
      switch (unitType) {
        case 0:
          return inputCount;
        case 1:
          return result.
            div(isForward ? 1 : dPrice).
            toFixed(contractPlaceInfo.baseCoinPlace, Decimal.ROUND_DOWN);
        case 2:
          return result.times(isForward ? dPrice : 1).
            toFixed(contractPlaceInfo.propertyPlace, Decimal.ROUND_DOWN);
      }
    };
  },
  /**
   * 币量转张数
   * @param state
   */
  [GETTER_TYPES.TRANSFER_AMOUNT_TO_COUNT](state) {
    const {contractInfo, unitType} = state;
    /**
     * 币量=>张数
     * 正向合约： N BaseSymbol / PreValue(BaseSymbol/张) = inputAmount(张)
     * 反向合约： (N BaseSymbol * inputPrice(PricedSymbol/BaseSymbol)) / PreValue(PricedSymbol/张) = inputAmount(张)
     * 正向合约：inputAmount PricedSymbol / inputPrice(PricedSymbol/BaseSymbol) / PreValue(BaseSymbol/张) = 张
     * 反向合约：inputAmount PricedSymbol / PreValue(PricedSymbol/张) = 张
     */
    return (inputAmount, inputPrice) => {
      if (unitType === 0) return inputAmount;

      inputAmount = new Decimal(isNaN(inputAmount) ? 0 : inputAmount);
      inputPrice = new Decimal(isNaN(inputPrice) ? 0 : inputPrice);

      let result = inputAmount.div(contractInfo.preValue);

      if (unitType === 1) {
        // 左币转张数
        if (contractInfo.forwardContractFlag) {
          // 正向合约
          result = inputAmount.div(contractInfo.preValue);
        } else {
          result = inputAmount.times(inputPrice).div(contractInfo.preValue);
        }
      } else if (unitType === 2) {
        // 右币转张数
        if (contractInfo.forwardContractFlag) {
          // 正向合约
          if (inputPrice.isZero()) {
            result = inputPrice;
          } else {
            result = inputAmount.div(inputPrice).div(contractInfo.preValue);
          }
        } else {
          result = inputAmount.div(contractInfo.preValue);
        }
      }

      return result.toFixed(0, Decimal.ROUND_DOWN);
    };
  },
};

export default getters;
