// @flow
import {MUTATION_TYPES, createDefaultStore} from "./types";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const state = () => createDefaultStore()

export {
    state,
    MUTATION_TYPES,
    mutations,
    actions,
    getters
}