// @flow
import { Decimal } from "decimal.js";
import {SUPPORTED_THEME} from 'bgconfig';

/**
 * 本文件负责声明各种实体，以及暴露生产默认类型的工厂
 */
export opaque type ProductCode = string;

/**
 * 统一叫法：合约相关精度配置实体，称作ContractPlaceInfo
 */
export type ContractPlaceInfo = {
  pricePlace: number, // 显示用一般价格精度
  delegatePricePlace: number, // 委托价格精度
  marginRatePlace: number, // 保证金率精度
  propertyPlace: number, // 显示用资产精度
  propertyHighPlace: number, // 高精度显示资产
  capitalRatePlace: number, // 资金费率精度
  baseCoinPlace: number, // 张数转币量所用精度
};

/**
 * 统一叫法：合约的相关配置信息实体，称为ContractInfo
 */
export type ContractInfo = {
  productCode: string, // 合约代号
  status: 0 | 1 | 2, // 合约状态 1-正常 0-下架 2-维护
  baseSymbol: string, // 左币符号
  quoteSymbol: string, // 保证金符号
  pricedSymbol: string, // 右币符号
  planMarketPrice: boolean, // 是否支持闪电计划
  marketPriceOpenLimitSize: number, // 市价开仓单笔数量限制
  marketPriceCloseLimitSize: number, // 市价平仓单笔数量限制
  autoAppendMargin: boolean, // 是否支持自动追加保证金
  forwardContractFlag: boolean, // ture-正向合约
  simulation: boolean, // true-模拟盘
  preValue: number, // 面值
  priceEndStep: number, // 委托价格步长
  longLimitBurstPrice: number, // 多仓爆仓价下限，小于该值不爆仓
  shortLimitBurstPrice: number, // 空仓爆仓价上限，大于该值显示不爆仓
  placeConfig?: ContractPlaceInfo,
  limitPriceFlag: 1 | 2 | 3, // 计算委托价格上下限用的参考，1-指数 2-市场 3-最宽限
  feeRate: number, // 手续费率
  openCostUpRatio: number, // 开仓成本上浮比例
  openCostFeeUpRatio: number, // 开仓手续费上浮比例
  buyLimitPriceRatio: number, // 最高买价上限比例
  sellLimitPriceRatio: number, // 最低卖价下限比例
  maxTradingUnit: number, // 最大交易张数
  maxLeverage: number, // 最大杠杆倍数
  webMarginScale: number[], // 杠杆节点
  priceUseCapitalRate: boolean, // 计算爆仓价是否使用资金费用
  priceUseFeeRate: boolean, // 20200819 新增爆仓价是否使用手续费
  indexDataSource: string, // 指数来源
  trackingSupport: boolean, // 是否支持跟单
  supportCrossMarginMode: boolean, // 是否支持全仓
  splitMargin: boolean, // 是否支持分仓
  hotP: boolean, // 是否是热门交易对
  newP: boolean // 是否是新交易对
};

/**
 * 统一叫法：仓位档位信息
 */
export type PositionLeverageLevel = {
  endUnit: number, // 终止张数
  keepMarginRate: number, // 维持保证金率
  level: number, // 档位index
  leverage: number, // 对应杠杆
  startUnit: number // 起始张数
};

/**
 * 统一叫法：市场信息===ticker
 */
export type Ticker = {
  productCode: ProductCode,
  price: string, // 市场价格
  fairPrice: string, // 合理标记价格
  indexPrice: string, // 指数价格
  change: string, // 涨跌幅
  high: string, // 最高价
  low: string, // 最低价
  vol: string, // 成交量
  holding: string, // 持仓量
  fundsRate: string, // 资金费率
  newP?: boolean, // 新交易对
  hotP?: boolean // 热门交易对
};

/**
 * 统一叫法：盘口信息
 * [0] 价格，[1] 数量
 */
export type DepthInfo = [string, string];

/**
 * 统一叫法：成交记录
 * [0] 时间 [1] 价格 [2] 数量 [3] 类型 1-买 2-卖
 */
export type HistoryInfo = [string, string, string, number];

/**
 * 统一叫法：资金费率（具体资金费率值，下一结算时间，可用于倒计时）
 */
export type FundsRateInfo = {
  currentValue: string, // 资金费用数值，
  nextTime: number // 下一结算时间， 是一个时间戳
};

/**
 * 统一叫法：用户资产（将原始数据流拆分得到）
 */
export type UserAssetsInfo = {
  balance: string, // 可用
  balanceText: string, // 显示用可用
  used: string, // 已用
  longLeverage: string, // 多仓杠杆
  shortLeverage: string, // 空仓杠杆
  freeze: string, // 冻结量
  cashgift: string, // 体验金
  longAccountAchievedProfits: string, // 多仓已实现盈亏
  shortAccountAchievedProfits: string, // 空仓已实现盈亏
  longAccountDelegateCount: string, // 多仓开仓委托张数
  shortAccountDelegateCount: string,
  holdModeEnum: string, // 原始持仓模型枚举 FIXED_MARGIN-逐仓 CROSS_MARGIN-全仓
  subHoldModeEnum: string, // 原始持仓模型枚举 MERGED-合仓 SPLIT-分仓
  isFixedMode: boolean, // true-逐仓模式
  isSplitMode: boolean, // true-分仓模式
  dTotalUpnl: null | Decimal, // 总未实现盈亏
  dTotalUsed: null | Decimal, // 总已用
  dTotalProfits: null | Decimal, // 总账户权益（仓位不是不同计算不同）
  crossMarginRateText: string, // 全仓模式保证金率(仅显示用)
  extraMM: string // 20200818新增，用户手续费，参与保证金率计算
};

/**
 * 统一叫法：当前委托
 */
export type CurrentEntrust = {
  amount: string, // 委托数量
  direction: number, // 方向 [1-10]
  done: string, // 已成交量张数
  forcePrice?: string, // 爆仓价格
  forceTime?: string, // 爆仓时间
  id: string, // 委托ID，撤单凭证
  orderType: 0 | 1, // 0-限价 1-市价
  price: string, // 委托价格
  source: number, // 来源 [1-15]
  status: number, // 状态 [1-5]
  time: string, // 委托时间
  presetTakeProfitPrice?: string, // 预设止盈价格
  presetStopLossPrice?: string // 预设止损价格
};

/**
 * 历史委托
 */
export type HistoryEntrust = {
  amount: string, // 委托张数
  arvPrice: string, // 成交均价
  dealCount: string, // 成交数量
  delegatePrice: "9526.0", // 委托价格
  direction: number, // 方向
  forcePrice: string, // 强平价格
  forceTime: string, // 强平时间
  orderType: number, // 委托类型
  pnl: string, // 已实现盈亏
  source: number, // 来源 系统委托还是手动委托
  status: 3, // 状态，完全成交/部分撤销等等
  time: string // 时间
};

/**
 * 统一叫法：当前计划（计划委托+止盈止损）
 */
export type CurrentPlans = {
  amount: string, // 委托张数
  createTime: string, // 创建时间
  createTradePrice: string, // 创建时市场价，用于判断触发条件大于等于
  direction: number, // 方向[1-10] 1开多 2开空 3平多 4平空 5强制减多 6强制减空 7协议平仓" + " 8协议平仓 9爆仓平多 10爆仓平空
  executeCount: string, // 已执行张数
  executePrice: string, // 执行价格
  id: string, // 计划ID，用于撤销
  planType: 0 | 1 | 2, // 计划类型 0-普通计划 1-止盈 2-止损
  status: 1 | 2 | 3 | 4, // 计划状态
  triggerPrice: string, // 触发价格
  triggerType: number // 触发类型 1-限价 x-市价
};

/**
 * 历史计划
 */
export type HistoryPlan = {
  amount: string, // 数量
  cancelReason: string, // 取消原因
  createTime: string, // 创建时间
  createTradePrice: string, // 创建时市场价格
  direction: number, // 方向[1-10]
  executeCount: string, // 执行数量
  planType: number, // 计划类型 0-普通计划 1-止盈 2-止损
  status: number, // 计划状态
  triggerPrice: string, // 触发价格
  triggerType: number // 触发类型
};

/**
 * 委托的成交明细
 */
export type EntrustDetail = {
  amount: string, // 成交数量
  dealPrice: string, // 成交均价
  direction: 1, // 方向[1-10]
  fee: string, // 手续费
  forcePrice: string, // 强平价格
  forceTime: string, // 强平时间
  pnl: string, // 已实现盈亏
  time: string // 时间
};

/**
 * 当前跟单和当前单带实体类似
 */
export type TraceOrderInfo = {
  teacherId: string, // 带单者ID，有这个字段表示为跟单类型
  closeDealCount: string, // 平仓张数
  orderNo: string, // 订单号
  productCode: string, // 合约名称
  position: string, // 仓位方向 多1空0
  openLevel: string, // 杠杆倍数
  openDelegateCount: string, // 开仓委托张数
  openDealCount: string, // 开仓成交张数
  openAvgPrice: string, // 开仓均价
  openMarginCount: string, // 累计放入保证金
  closeAvgPrice: string, // 平仓均价
  achievedProfits: string, // 已实现盈亏
  stopProfitPrice: string, // 止盈价格 49%
  stopLossPrice: string, // 止损价格 - 19%
  openTime: number, // 开仓时间
  closeTime: number, // 平仓时间
  openFee: string, // 开仓累计手续费
  closeFee: string, // 平仓累计手续费
  capitalPay: string, // 资金费率支出
  capitalReceive: string, // 资金费率获取
  status: number // 0初始状态，1开仓完成状态，2平仓完成状态
};

/**
 * 统一叫法：ADL减仓队列（单位杠杆净盈利排序）
 * [0] 区间起始 [1] 区间结束
 */
export type ADLInfo = [number, number];

/**
 * 统一叫法：保证金流水（由于逐仓模式下以保证金账户为最小单位）
 * 全仓模式将引用全新的财务记录NEW
 */
export type PositionHistory = {
  position: string, // "1"多仓 "2"空仓
  time: string, // 时间
  type: number, // 类型
  vol: string // 具体数量
};

/**
 * 统一叫法：财务记录NEW
 */
export type FinancialRecord = {
  positionShowType: number, // 仓位类型 0-无 1-全仓 2-逐仓多头 3-逐仓多头
  bizTime: number, // 时间
  operationType: 0, // 减少保证金 增加保证金 开仓保证金 资金费用 手续费 已实现盈亏 平仓结算 协议平仓结算 爆仓结算
  amount: string, // 金额
  count: string, // 张数
  fee: string, // 手续费
  remarkType: string // 备注类型
};

/**
 * 统一叫法：原始仓位信息
 */
export type PositionInfo = {
  amount: string, // 持仓数量
  autoAppendMargin: number, // 是否开启自动追加保证金， 全仓将不具备这个字段
  delegateCount: string, // 委托张数
  freezeCount: string, // 平仓冻结张数
  margin: string, // 保证金量
  openPrice: string, // 开仓均价
  pnl: string, // 已实现盈亏
  position: number, // 1多仓 2空仓
  reducePrice: string, // 预估强平价
  openOrderNo: string // 分仓仓位的开仓订单编号
};

/**
 * 显示用仓位信息（包含一些计算量）
 * 未实现盈亏，回报率，自动减仓队列位置，保证金率
 */
export type FixedPositionInfo = PositionInfo & {
  holdCount: number, // 持仓+冻结 表达完整的仓位
  dUpnl: null | Decimal, // 未实现盈亏 in decimal
  dMarginRate: null | Decimal, // 保证金率 in decimal
  dRoe: null | Decimal, // 回报率 in decimal
  dValue: null | Decimal, // 开仓均价-仓位价值 in decimal
  dCurrentValue: null | Decimal, // 合理标记价格-仓位价值 in decimal
  adlQueueIndex: number // 自动减仓队列排序
};

/**
 * 合约交易Vuex状态树
 */
export type IState = {
  apiBaseUrl: string, // api基础地址
  layout: string, // 新增多布局模式表示字段
  stateNumber: number, // 状态码 0-初始态 1-配置就绪态 2-ticker就绪态 3-Pending态  (ticker就绪态可以认为是最终就绪态)
  productCode: ProductCode,
  contractList: ContractInfo[], // 原始合约配置列表
  allTicker: Ticker[], // 全体市场信息
  ticker: Ticker, // 当前交易对市场信息
  contractInfo: ContractInfo,
  contractPlaceInfo: ContractPlaceInfo,
  positionLeverageLevel: PositionLeverageLevel[], // 仓位档位梯度信息，切换交易对应当清空
  systemTime: number, // 系统时间
  fundsRateInfo: FundsRateInfo, // 资金费用相关信息
  asks: DepthInfo[], // 卖盘
  bids: DepthInfo[], // 买盘
  history: HistoryInfo[], // 成交记录，增量更新
  assets: UserAssetsInfo, // 用户资产信息
  assetsAll: UserAssetsInfo[], // 用户资产信息
  currentEntrust: CurrentEntrust[], // 当前委托信息
  currentPlans: CurrentPlans[], // 当前计划
  historyPlans: HistoryPlan[], // 历史计划
  entrustDetail: EntrustDetail[], // 成交明细
  historyEntrusts: HistoryEntrust[], // 历史委托
  traceOrders: TraceOrderInfo[], // 当前跟单/当前带单
  positionHistory: PositionHistory[], // 保证金流水
  financialRecords: FinancialRecord[], // 新保证金流水
  position: PositionInfo[], // 原始仓位信息
  positionAll: PositionInfo[], // 原始仓位信息
  longPositionInfo: FixedPositionInfo, // 多仓仓位信息
  shortPositionInfo: FixedPositionInfo, // 空仓仓位信息
  longADLQueue: ADLInfo[], // 多仓自动减仓队列范围信息
  shortADLQueue: ADLInfo[], // 空仓自动减仓队列范围信息
  typeLeverageDialog: number, // 调整杠杆的方向
  showLeverageDialog: boolean, // 调整杠杆弹窗的显示控制
  showContractProtocol: boolean, // 开通合约协议的显示控制
  showCheckLever: boolean, // 手机绑定提示的显示控制
  userSelectDepth: string, // 用户点击盘口时选择的信息（仅价格）
  userSelectInfo: {
    price: string,
    amount: string
  }, // 用户点击盘口选择的信息，价格+数量
  userConfig: UserConfig, // 用户配置保存在localStorage中
  asksTotal: number, // 卖盘15档总量，
  bidsTotal: number, // 买盘15档总量
  tradeLever: number, // 用户身份 -1表示不明确，当前跟单/当前带单tab都不显示 0-普通用户 1-交易员
  unitType: number, // 交易数量单位，仅影响委托组件和深度组件
  layoutReady: boolean, // 布局已设置，可以开始布局
  reversePositionChecked: boolean, // 反手是否包含委托部分，默认true
};

// 支持的布局
export const SUPPORTED_LAYOUT = ["left", "center", "right"]

/**
 * 包含挂单吃单选项在内的用户设置
 */
export type UserConfig = {
  agreedTime: number, // 同意使用的时间
  alertForLazyOp: boolean, // 挂单吃单是否提示
  alertForProfitControl: boolean, // 止盈止损是否提示
  switchForLazy: boolean, // 是否使用吃单挂单
  directionForLazy: number, // 挂单吃单的模式 1-开仓 2-平仓
  checkForMakerPO: boolean, // 挂单是否使用"只做Maker"模式
  checkForTakerIOC: boolean, // 吃单是否使用"IOC"
  checkForTakerFOK: boolean, // 吃单是否使用"FOK"
  checkForOpenMode: number, // 开仓数量模式 1-百分比 2-盘口
  openPercent: number, // 开仓数量百分比模式具体百分比
  checkForCloseMode: number, // 平仓数量模式 1-百分比 2-盘口
  closePercent: number, // 平仓数量百分比模式具体百分比
  isReady: boolean // 配置是否就绪
};

/**
 * 合约委托参数
 */
export type ContractPlaceParam = {
  amount: number, // 张数
  caitalPwd?: string, // 资金密码
  requestTime?: number, // 资金密码时间戳
  matchType: number, // 0-限价 1-市价
  productCode: ProductCode, // 合约
  side: number, // 仓位方向 1-多仓 2-空仓
  timeInForce?: number, // 0-普通 1-po 2-ioc 3-fok
  tradePrice: number, // 委托价格
  type: number // 方向 1-开仓 2-平仓
};

/**
 * getters类型常量
 */
export const GETTER_TYPES = {
  KEEP_MARGIN_RATE: "KEEP_MARGIN_RATE",
  LEVERAGE_MAX_HOLD: "LEVERAGE_MAX_HOLD",
  HOLD_MAX_LEVERAGE: "HOLD_MAX_LEVERAGE",
  CAN_MAX_OPEN: "CAN_MAX_OPEN",
  _CAN_MAX_OPEN: "_CAN_MAX_OPEN",
  SEL_CAN_MAX_OPEN: "SEL_CAN_MAX_OPEN",
  _SEL_CAN_MAX_OPEN: "_SEL_CAN_MAX_OPEN",
  STEPPED_PRICE: "STEPPED_PRICE",
  CHECK_INPUT_PRICE: "CHECK_INPUT_PRICE",
  TRANSFER_AMOUNT_TO_COUNT: "TRANSFER_AMOUNT_TO_COUNT",
  TRANSFER_COUNT_TO_AMOUNT: "TRANSFER_COUNT_TO_AMOUNT"
};

/**
 * mutations类型常量
 */
export const MUTATION_TYPES = {
  PENDING_STATE: "PENDING_STATE",
  INIT_THEME: "INIT_THEME",
  SET_APIBASEURL: "SET_APIBASEURL",
  SET_THEME: "SET_THEME",
  SET_LAYOUT: "SET_LAYOUT",
  SET_PRODUCT_CODE: "SET_PRODUCT_CODE",
  SET_SEL_PRODUCT_CODE: "SET_SEL_PRODUCT_CODE",
  INIT_CONTRACT_LIST: "INIT_CONTRACT_LIST",
  SET_CONTRACT_INFO: "SET_CONTRACT_INFO",
  SET_SEL_CONTRACT_INFO: "SET_SEL_CONTRACT_INFO",
  SET_CONTRACT_PLACE_INFO: "SET_CONTRACT_PLACE_INFO",
  SET_POSITION_RISK_LEVEL: "SET_POSITION_RISK_LEVEL",
  SET_POSITION_LEVERAGE_LEVEL_ALL: "SET_POSITION_LEVERAGE_LEVEL_ALL",
  UPDATE_ALL_TICKER: "UPDATE_ALL_TICKER",
  UPDATE_KLINE_LAST_CLOSE_DATA: "UPDATE_KLINE_LAST_CLOSE_DATA",
  UPDATE_TICKER: "UPDATE_TICKER",
  UPDATE_SEL_TICKER: "UPDATE_SEL_TICKER",
  UPDATE_SYSTEM_TIME: "UPDATE_SYSTEM_TIME",
  UPDATE_FUNDS_RATE: "UPDATE_FUNDS_RATE",
  UPDATE_DEPTH: "UPDATE_DEPTH",
  UPDATE_HISTORY: "UPDATE_HISTORY",
  UPDATE_USER_ASSETS: "UPDATE_USER_ASSETS",
  UPDATE_SEL_USER_ASSETS: "UPDATE_SEL_USER_ASSETS",
  UPDATE_USER_ASSETS_All: "UPDATE_USER_ASSETS_All",
  UPDATE_CURRENT_PLANS_ALL: "UPDATE_CURRENT_PLANS_ALL",
  UPDATE_CURRENT_ENTRUSTS_ALL: "UPDATE_CURRENT_ENTRUSTS_ALL",
  UPDATE_LIST: "UPDATE_LIST",
  UPDATE_POSITION: "UPDATE_POSITION",
  UPDATE_POSITION_ALL: "UPDATE_POSITION_ALL",
  UPDATE_FIXED_POSITION: "UPDATE_FIXED_POSITION",
  UPDATE_SEL_POSITION: "UPDATE_SEL_POSITION",
  UPDATE_ADL: "UPDATE_ADL",
  UPDATE_ADL_ALL: "UPDATE_ADL_ALL",
  UPDATE_LEVERAGE_DIALOG: "UPDATE_LEVERAGE_DIALOG",
  UPDATE_SEL_LEVERAGE_DIALOG: "UPDATE_SEL_LEVERAGE_DIALOG",
  UPDATE_PROTOCOL_DIALOG: "UPDATE_PROTOCOL_DIALOG",
  UPDATE_CHECK_LEVER: "UPDATE_CHECK_LEVER",
  UPDATE_USER_SELECT_DEPTH: "UPDATE_USER_SELECT_DEPTH",
  CLEAR_USER_DATA: "CLEAR_USER_DATA",
  CLEAR_BASE_STATE: "CLEAR_BASE_STATE",
  UPDATE_USER_CONFIG: "UPDATE_USER_CONFIG",
  UPDATE_DEPTH_SUM: "UPDATE_DEPTH_SUM",
  SET_TRADE_LEVER: "SET_TRADE_LEVER",
  SET_UNIT_TYPE: "SET_UNIT_TYPE",
  UPDATE_REVERSE_CHECKED: "UPDATE_REVERSE_CHECKED",
  UPDATE_CONTRACT_PRICE: "UPDATE_CONTRACT_PRICE",
  UPDATE_LOGIN_STATUS: "UPDATE_LOGIN_STATUS",
  SET_WS_SPEED: "SET_WS_SPEED",
  SET_SHAREPOSTERS: 'SET_SHAREPOSTERS'
};

/**
 * actions类型常量
 */
export const ACTION_TYPES = {
  INIT_ALL: "INIT_ALL",
  INIT_CONTRACT_LIST: "INIT_CONTRACT_LIST",
  INIT_CONTRACT_LEVERAGE_LIST: "INIT_CONTRACT_LEVERAGE_LIST",
  UPDATE_ALL_TICKER: "UPDATE_ALL_TICKER",
  UPDATE_POSITION: "UPDATE_POSITION",
  CALCULATE_POSITION: "CALCULATE_POSITION",
  UPDATE_POSITION_ALL: "UPDATE_POSITION_ALL",
  CHECKED_POSITION_ALL: "CHECKED_POSITION_ALL",
  CLEAR_USER_DATA: "CLEAR_USER_DATA",
  UPDATE_ASSETS: "UPDATE_ASSETS",
  UPDATE_ASSETS_ALL: "UPDATE_ASSETS_ALL",
  UPDATE_USER_CONFIG: "UPDATE_USER_CONFIG",
  UPDATE_DEPTH: "UPDATE_DEPTH",
  LAZY_MAKER_PLACE: "LAZY_MAKER_PLACE",
  LAZY_TAKER_PLACE: "LAZY_TAKER_PLACE",
  FETCH_SHAREPOSTERS: 'FETCH_SHAREPOSTERS'
};

/**
 * 创建默认状态树
 */
export function createDefaultStore(): IState {
  return {
    layout: SUPPORTED_LAYOUT[2],
    stateNumber: 0,
    contractList: [],
    allTicker: [],
    klineLastCloseData: {
      price: 0,
      prevPrice: 0
    },
    ticker: createDefaultTicker(),
    selTicker: createDefaultTicker(),
    productCode: "-/-",
    selProductCode: "",
    contractInfo: createDefaultContractInfo(),
    selContractInfo: createDefaultContractInfo(),
    contractPlaceInfo: createDefaultContractPlaceInfo(),
    positionLeverageLevel: [],
    positionLeverageLevelAll: [],
    systemTime: 0,
    fundsRateInfo: {
      currentValue: "0",
      nextTime: 0
    },
    asks: [],
    bids: [],
    history: [],
    assets: createDefaultAssetsInfo(),
    selAssets: createDefaultAssetsInfo(),
    assetsAll: [],
    currentEntrust: [],
    currentEntrustsAll: [],
    currentPlans: [],
    currentPlansAll: [],
    historyPlans: [],
    entrustDetail: [],
    historyEntrusts: [],
    traceOrders: [],
    positionHistory: [],
    financialRecords: [],
    position: [],
    positionAll: [],
    longPositionInfo: createDefaultPositionInfo(1),
    shortPositionInfo: createDefaultPositionInfo(2),
    selLongPositionInfo: createDefaultPositionInfo(1),
    selShortPositionInfo: createDefaultPositionInfo(2),
    longADLQueue: [],
    shortADLQueue: [],
    ADLQueueAll: [],
    typeLeverageDialog: 1, // 默认多仓
    showLeverageDialog: false,
    selTypeLeverageDialog: 1, // 默认多仓
    selShowLeverageDialog: false,
    showContractProtocol: false,
    showCheckLever: false,
    userSelectDepth: "",
    userSelectInfo: {
      price: "",
      amount: ""
    },
    userConfig: createDefaultUserConfig(),
    asksTotal: 1, // 不至于算比例得到NaN 1/(1+1)*100%=50%
    bidsTotal: 1,
    tradeLever: -1,
    unitType: 0, // 0-一般的张数单位 1-币量单位 2-USDT单位
    layoutReady: true,
    reversePositionChecked: true,
    wsSpeed: 9999,
    sharePosters: [] // 分享海报
  };
}

export function createDefaultContractInfo(): ContractInfo {
  return {
    productCode: "-/-",
    status: 1, // 合约状态 1-正常 0-下架 2-维护
    baseSymbol: "-", // 左币符号
    quoteSymbol: "-", // 保证金符号
    pricedSymbol: "-", // 右币符号
    planMarketPrice: false, // 是否支持闪电计划
    marketPriceOpenLimitSize: 1000, // 市价开仓单笔数量限制
    marketPriceCloseLimitSize: 1000, // 市价平仓单笔数量限制
    autoAppendMargin: false, // 是否支持自动追加保证金
    forwardContractFlag: true, // ture-正向合约
    simulation: false, // true-模拟盘
    preValue: 1, // 面值
    priceEndStep: 5, // 委托价格步长
    longLimitBurstPrice: 0.1, // 多仓爆仓价下限，小于该值不爆仓
    shortLimitBurstPrice: 1000000, // 空仓爆仓价上限，大于该值显示不爆仓
    limitPriceFlag: 3, // 计算委托价格上下限用的参考，1-指数 2-市场 3-最宽限
    feeRate: 0.0006, // 手续费率
    openCostUpRatio: 0.01, // 开仓成本上浮比例
    openCostFeeUpRatio: 0.005, // 开仓手续费上浮比例
    buyLimitPriceRatio: 0.1, // 最高买价上限比例
    sellLimitPriceRatio: 0.1, // 最低卖价下限比例
    maxTradingUnit: NaN, // 最大交易张数
    maxLeverage: 100, // 最大杠杆倍数
    webMarginScale: [1, 2, 3, 5, 10, 20, 100], // 杠杆节点
    priceUseCapitalRate: false, // 计算爆仓价是否使用资金费用
    priceUseFeeRate: true,
    indexDataSource: "-",
    trackingSupport: false,
    supportCrossMarginMode: false, // 是否支持全仓
    splitMargin: false,  // 是否支持分仓
    hotP: false,
    newP: false
  };
}

export function createDefaultContractPlaceInfo(): ContractPlaceInfo {
  return {
    pricePlace: 2, // 显示用一般价格精度
    delegatePricePlace: 1, // 委托价格精度
    marginRatePlace: 4, // 保证金率精度
    propertyPlace: 4, // 显示用资产精度
    propertyHighPlace: 8, // 高精度显示资产
    capitalRatePlace: 4, // 资金费率精度
    baseCoinPlace: 4, // 张数转币量所用精度
  };
}

export function createDefaultTicker(): Ticker {
  return {
    productCode: "",
    price: "0", // 市场价格
    fairPrice: "0", // 合理标记价格
    indexPrice: "0", // 指数价格
    change: "0", // 涨跌幅
    high: "0", // 最高价
    low: "0", // 最低价
    vol: "0", // 成交量
    holding: "0", // 持仓量
    fundsRate: "0" // 资金费率
  };
}

export function createDefaultAssetsInfo(): UserAssetsInfo {
  return {
    balance: "0", // 可用
    balanceText: "0",
    cashgift: "0", // 体验金
    used: "0", // 已用
    longLeverage: "20", // 多仓杠杆
    shortLeverage: "20", // 空仓杠杆
    freeze: "0",
    longAccountAchievedProfits: "0", // 多仓已实现盈亏
    shortAccountAchievedProfits: "0", // 空仓已实现盈亏
    longAccountDelegateCount: "0", // 多仓开仓委托张数
    shortAccountDelegateCount: "0",
    holdModeEnum: "CROSS_MARGIN", //全仓,逐仓
    subHoldModeEnum: "MERGED", //分合仓
    isFixedMode: false, // true-逐仓模式
    isSplitMode: false, // true-分仓模式
    dTotalUpnl: null,
    dTotalUsed: null,
    dTotalProfits: null,
    crossMarginRateText: "-",
    extraMM: "0"
  };
}

export function createDefaultPositionInfo(
  positionType: number
): FixedPositionInfo {
  return {
    amount: "0", // 持仓数量
    autoAppendMargin: 0, // 是否开启自动追加保证金， 全仓将不具备这个字段
    delegateCount: "0", // 委托张数
    freezeCount: "0", // 平仓冻结张数
    margin: "0", // 保证金量
    openPrice: "0", // 开仓均价
    pnl: "0", // 已实现盈亏
    position: positionType, // 1多仓 2空仓
    reducePrice: "0", // 预估强平价
    holdCount: 0,
    dUpnl: null, //
    dValue: null,
    dCurrentValue: null,
    dRoe: null,
    dMarginRate: null,
    adlQueueIndex: -1
  };
}

/**
 * 默认的用户配置
 * @return {{alertForLazyOp: boolean, alertForProfitControl: boolean}}
 */
export function createDefaultUserConfig(): UserConfig {
  return {
    agreedTime: -1,
    alertForLazyOp: true,
    alertForProfitControl: true,
    switchForLazy: false,
    directionForLazy: 1,
    checkForMakerPO: true, // 挂单是否使用"只做Maker"模式
    checkForTakerIOC: true, // 吃单是否使用"IOC"
    checkForTakerFOK: false, // 吃单是否使用"FOK"
    checkForOpenMode: 1, // 开仓数量模式 1-百分比 2-盘口
    openPercent: 100, // 开仓数量百分比模式具体百分比
    checkForCloseMode: 1, // 平仓数量模式 1-百分比 2-盘口
    closePercent: 100, // 平仓数量百分比模式具体百分比
    isReady: false
  };
}
