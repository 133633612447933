// @flow
import type {MutationTree} from "vuex";
import {
  createDefaultAssetsInfo, createDefaultContractPlaceInfo,
  createDefaultPositionInfo,
  createDefaultTicker,
  createDefaultUserConfig,
  MUTATION_TYPES, SUPPORTED_LAYOUT,
} from "./types";
import type {
  ADLInfo,
  ContractInfo,
  ContractPlaceInfo,
  CurrentEntrust,
  CurrentPlans,
  DepthInfo,
  EntrustDetail,
  FinancialRecord,
  FixedPositionInfo,
  FundsRateInfo,
  HistoryEntrust,
  HistoryInfo,
  HistoryPlan,
  IState,
  PositionHistory,
  PositionInfo,
  PositionLeverageLevel,
  ProductCode,
  Ticker,
  TraceOrderInfo,
  UserAssetsInfo
} from "./types";

import LocalCookie , { CookieKey }   from '~/utils/localCookie'

const mutations: MutationTree<IState> = {
  [MUTATION_TYPES.PENDING_STATE](state, forced: boolean = false) {
    if (forced) {
      // 强制模式：仅在离开合约页面时
      state.stateNumber = 0;
    } else {
      // 普通模式：就绪态=》pending态
      state.stateNumber === 2 && (state.stateNumber = 3);
    }
  },
  /**
   * 初始化主题+布局
   * @param state
   */
  [MUTATION_TYPES.INIT_THEME](state) {
    let layout =LocalCookie.get(CookieKey.LAYOUT) || SUPPORTED_LAYOUT[2];

    if (!SUPPORTED_LAYOUT.includes(layout)) {
      layout = SUPPORTED_LAYOUT[2]
    }
    state.layout = layout
    state.layoutReady = true
  },
  /**
   * 设置布局
   * @param state
   * @param layout
   */
  [MUTATION_TYPES.SET_LAYOUT](state, layout: string) {
    if (SUPPORTED_LAYOUT.includes(layout)) {
      state.layout = layout
      //localStorage.setItem("user::swap::layout", layout)
      LocalCookie.set(CookieKey.LAYOUT,layout);
    }
  },
  /**
   * 设置当前产品代码
   * @param state
   * @param productCode
   */
  [MUTATION_TYPES.SET_PRODUCT_CODE](state, productCode: ProductCode) {
    state.productCode = productCode;
  },
  /**
   * 设置产品代码 - 选中
   * @param state
   * @param selProductCode
   */
  [MUTATION_TYPES.SET_SEL_PRODUCT_CODE](state, selProductCode: ProductCode) {
    state.selProductCode = selProductCode;
  },
  /**
   * 初始化全部合约配置列表
   * @param state
   * @param contractList
   */
  [MUTATION_TYPES.INIT_CONTRACT_LIST](state, contractList) {
    state.contractList = contractList;
    state.stateNumber = 1; // 进入配置就绪态，等待ticker（首次：websocket严格等待contractList）
  },
  /**
   * 设置合约配置
   * @param state
   * @param contractInfo
   */
  [MUTATION_TYPES.SET_CONTRACT_INFO](state, contractInfo: ContractInfo) {
    // 为了保护字段
    let oldInfo = state.contractInfo;
    state.contractInfo = Object.assign({}, oldInfo, contractInfo);
  },
  /**
   * 设置合约配置 - 选中
   * @param state
   * @param contractInfo
   */
  [MUTATION_TYPES.SET_SEL_CONTRACT_INFO](state, contractInfo: ContractInfo) {
    // 为了保护字段
    let oldInfo = state.contractInfo;
    state.selContractInfo = Object.assign({}, oldInfo, contractInfo);
  },
  /**
   * 设置合约精度配置
   * @param state
   * @param contractPlaceInfo
   */
  [MUTATION_TYPES.SET_CONTRACT_PLACE_INFO](
    state,
    contractPlaceInfo: ContractPlaceInfo
  ) {
    let oldInfo = createDefaultContractPlaceInfo();
    state.contractPlaceInfo = Object.assign({}, oldInfo, contractPlaceInfo);
  },
  /**
   * 设置仓位档位信息
   * @param state
   * @param positionRiskLevel
   */
  [MUTATION_TYPES.SET_POSITION_RISK_LEVEL](
    state,
    positionRiskLevel: PositionLeverageLevel[]
  ) {
    state.positionLeverageLevel = positionRiskLevel;
  },
  /**
   * 设置仓位档位信息集合
   * @param state
   * @param data
   */
  [MUTATION_TYPES.SET_POSITION_LEVERAGE_LEVEL_ALL](state, data) {
    state.positionLeverageLevelAll = data;
  },
  /**
   * 更新所有市场信息
   * @param state
   * @param tickers
   */
  [MUTATION_TYPES.UPDATE_ALL_TICKER](state, tickers: Ticker[]) {
    state.allTicker = tickers;
  },
  /**
   * 更新Kline last close data
   * @param state
   * @param tickers
   */
  [MUTATION_TYPES.UPDATE_KLINE_LAST_CLOSE_DATA](state, data) {
    state.klineLastCloseData = data;
  },
  /**
   * 更新当前交易对的市场信息
   * @param state
   * @param ticker
   */
  [MUTATION_TYPES.UPDATE_TICKER](state, ticker: Ticker) {
    state.ticker = Object.assign({}, createDefaultTicker(), ticker);
    // 如果当前状态是在等待ticker信息
    if (state.stateNumber === 1 || state.stateNumber === 3) {
      state.stateNumber = 2; // 可以认为切换交易对就绪
    }
  },
  /**
   * 更新交易对的市场信息 - 选中
   * @param state
   * @param ticker
   */
  [MUTATION_TYPES.UPDATE_SEL_TICKER](state, ticker: Ticker) {
    state.selTicker = Object.assign({}, createDefaultTicker(), ticker);
  },
  /**
   * 更新合约显示的价格
   * @param state
   * @param price
   */
  [MUTATION_TYPES.UPDATE_CONTRACT_PRICE](state, price:string) {
    if (state.ticker && state.ticker.price && price) state.ticker.price = price;
  },
  /**
   * 更新系统时间
   * @param state
   * @param systemTime
   */
  [MUTATION_TYPES.UPDATE_SYSTEM_TIME](state, systemTime: number) {
    state.systemTime = systemTime;
  },
  /**
   * 更新资金费用信息（合并模式）
   * @param state
   * @param fundsRateInfo
   */
  [MUTATION_TYPES.UPDATE_FUNDS_RATE](state, fundsRateInfo: FundsRateInfo) {
    state.fundsRateInfo = Object.assign({}, state.fundsRateInfo, fundsRateInfo);
  },
  /**
   * 更新盘口
   * @param state
   * @param info
   */
  [MUTATION_TYPES.UPDATE_DEPTH](
    state,
    info: { asks: DepthInfo[], bids: DepthInfo[] }
  ) {
    state.asks = info.asks.slice(0, 15);
    state.bids = info.bids.slice(0, 15);
  },
  /**
   * 更新成交历史，增量取50条
   * @param state
   * @param history
   */
  [MUTATION_TYPES.UPDATE_HISTORY](state, history: HistoryInfo[]) {
    state.history =
      history.length > 0
        ? history.concat(state.history).splice(0, 50)
        : history;
  },
  /**
   * 更新用户资产信息
   * @param state
   * @param userAssetsInfo
   */
  [MUTATION_TYPES.UPDATE_USER_ASSETS](state, userAssetsInfo: UserAssetsInfo) {
    state.assets = Object.assign({}, state.assets, userAssetsInfo);
  },
  /**
   * 更新用户资产信息 - 选中
   * @param state
   * @param userAssetsInfo
   */
  [MUTATION_TYPES.UPDATE_SEL_USER_ASSETS](state, userAssetsInfo: UserAssetsInfo) {
    state.selAssets = Object.assign({}, state.selAssets, userAssetsInfo);
  },
  /**
   * 更新用户资产信息集合
   * @param state
   * @param userAssetsInfoList
   */
  [MUTATION_TYPES.UPDATE_USER_ASSETS_All](state, userAssetsInfoList: UserAssetsInfo[]) {
    state.assetsAll = userAssetsInfoList;
  },
  /**
   * 更新currentPlansAll
   * @param state
   * @param userAssetsInfoList
   */
  [MUTATION_TYPES.UPDATE_CURRENT_PLANS_ALL](state, data) {
    state.currentPlansAll = data || [];
  },
  /**
   * 更新currentEntrustAll
   * @param state
   * @param userAssetsInfoList
   */
  [MUTATION_TYPES.UPDATE_CURRENT_ENTRUSTS_ALL](state, data) {
    state.currentEntrustsAll = data || [];
  },
  /**
   * 更新各种列表
   * @param state
   * @param listInfo
   */
  [MUTATION_TYPES.UPDATE_LIST](state, listInfo: { type: number, list: any }) {
    switch (listInfo.type) {
      case 1:
        // 当前委托
        state.currentEntrust = (listInfo.list: CurrentEntrust[]);
        break;
      case 2:
        // 历史委托
        state.historyEntrusts = (listInfo.list: HistoryEntrust[])
          .concat(state.historyEntrusts)
          .slice(0, 10);
        break;
      case 3:
        // 成交明细
        state.entrustDetail = (listInfo.list: EntrustDetail[])
          .concat(state.entrustDetail)
          .slice(0, 10);
        break;
      case 4:
        // 当前计划
        state.currentPlans = (listInfo.list: CurrentPlans[]);
        break;
      case 5:
        // 历史计划
        state.historyPlans = (listInfo.list: HistoryPlan[])
          .concat(state.historyPlans)
          .slice(0, 10);
        break;
      case 6:
        // 跟单信息
        state.traceOrders = (listInfo.list: TraceOrderInfo[]);
        break;
      case 7:
        // 保证金流水
        state.positionHistory = (listInfo.list: PositionHistory[])
          .concat(state.positionHistory)
          .slice(0, 10);
        break;
      case 8:
        // 财务记录NEW
        state.financialRecords = (listInfo.list: FinancialRecord[])
          .concat(state.financialRecords)
          .slice(0, 10);
        break;
      default:
    }
  },
  /**
   * 更新仓位原始数据
   * @param state
   * @param positions
   */
  [MUTATION_TYPES.UPDATE_POSITION](state, positions: PositionInfo[]) {
    state.position = positions;
  },
  /**
   * 更新仓位原始数据 - all
   * @param state
   * @param positions
   */
  [MUTATION_TYPES.UPDATE_POSITION_ALL](state, positions: PositionInfo[]) {
    state.positionAll = positions;
  },
  /**
   * 单独更新多空仓数据
   * @param state
   * @param positionInfo
   */
  [MUTATION_TYPES.UPDATE_FIXED_POSITION](
    state,
    positionInfo: FixedPositionInfo
  ) {
    if (positionInfo.position === 1) {
      state.longPositionInfo = positionInfo;
    } else {
      state.shortPositionInfo = positionInfo;
    }
  },
  /**
   * 单独更新多空仓数据 - 选中
   * @param state
   * @param positionInfo
   */
  [MUTATION_TYPES.UPDATE_SEL_POSITION](state, positionInfo: FixedPositionInfo) {
    if (positionInfo.position === 1) {
      state.selLongPositionInfo = positionInfo;
    } else {
      state.selShortPositionInfo = positionInfo;
    }
  },
  /**
   * 更新ADL队列信息
   * @param state
   * @param adlInfo
   */
  [MUTATION_TYPES.UPDATE_ADL](
    state,
    adlInfo: { long: ADLInfo[], short: ADLInfo[] }
  ) {
    state.longADLQueue = adlInfo.long;
    state.shortADLQueue = adlInfo.short;
  },
  /**
   * 更新ADL队列信息 - 集合
   * @param state
   * @param adlInfo
   */
  [MUTATION_TYPES.UPDATE_ADL_ALL](state, data: []) {
    state.ADLQueueAll = data;
  },
  /**
   * 按类型打开弹窗（关闭弹窗一般由弹窗本身提交）
   * @param state
   * @param info
   */
  [MUTATION_TYPES.UPDATE_LEVERAGE_DIALOG](
    state,
    info: { show: boolean, type: number }
  ) {
    state.typeLeverageDialog = info.type;
    state.showLeverageDialog = info.show;
  },
  /**
   * 按类型打开弹窗（关闭弹窗一般由弹窗本身提交） - 选中
   * @param state
   * @param info
   */
  [MUTATION_TYPES.UPDATE_SEL_LEVERAGE_DIALOG](state, info: { show: boolean, type: number }) {
    state.selTypeLeverageDialog = info.type;
    state.selShowLeverageDialog = info.show;
  },
  /**
   * 更新开通合约协议弹窗的显示
   * @param state
   * @param newValue
   */
  [MUTATION_TYPES.UPDATE_PROTOCOL_DIALOG](state, newValue: boolean) {
    state.showContractProtocol = newValue;
  },
  /**
   * 更新手机绑定提示的显示
   * @param state
   * @param newValue
   */
  [MUTATION_TYPES.UPDATE_CHECK_LEVER](state, newValue: boolean) {
    state.showCheckLever = newValue;
  },
  /**
   * 用户点击盘口表示选中价格，委托区域和平仓区域会使用这个价格进行操作
   * @param state
   * @param newValue
   */
  [MUTATION_TYPES.UPDATE_USER_SELECT_DEPTH](state, newValue: any) {
    state.userSelectInfo = newValue;
  },
  /**
   * 清空用户信息
   * @param state
   */
  [MUTATION_TYPES.CLEAR_USER_DATA](state) {
    state.assets = createDefaultAssetsInfo();
    state.assetsAll = [];
    state.position = [];
    state.positionAll = [];
    state.currentEntrust = [];
    state.historyEntrusts = [];
    state.entrustDetail = [];
    state.positionHistory = [];
    state.currentPlans = [];
    state.historyPlans = [];
    state.traceOrders = [];
    state.longPositionInfo = createDefaultPositionInfo(1);
    state.shortPositionInfo = createDefaultPositionInfo(2);
    state.financialRecords = [];
  },
  /**
   * 清空其他基本数据
   * @param state
   */
  [MUTATION_TYPES.CLEAR_BASE_STATE](state) {
    state.asks = [];
    state.bids = [];
    state.contractList = [];
    state.allTicker = [];
    state.fundsRateInfo = {
      currentValue: "0",
      nextTime: 0
    };
  },
  /**
   * 更新用户配置
   * @param state
   * @param newConfig
   */
  [MUTATION_TYPES.UPDATE_USER_CONFIG](state, newConfig) {
    newConfig = newConfig || createDefaultUserConfig();
    newConfig.isReady = true;
    state.userConfig = newConfig;
  },
  /**
   * 更新深度汇总数量
   * @param state
   * @param info
   */
  [MUTATION_TYPES.UPDATE_DEPTH_SUM](
    state,
    info: { asksTotal: number, bidsTotal: number }
  ) {
    if (info.asksTotal + info.asksTotal <= 0) {
      return;
    }
    state.asksTotal = info.asksTotal;
    state.bidsTotal = info.bidsTotal;
  },
  /**
   * 更新用户身份
   * @param state
   * @param tradeLever
   */
  [MUTATION_TYPES.SET_TRADE_LEVER](state, tradeLever: number) {
    state.tradeLever = tradeLever;
  },
  /**
   * 变更单位类型
   * @param state
   * @param unitType
   */
  [MUTATION_TYPES.SET_UNIT_TYPE](state, unitType: number) {
    state.unitType = unitType
  },
  [MUTATION_TYPES.UPDATE_REVERSE_CHECKED](state, checked: boolean) {
    state.reversePositionChecked = checked
  },
  [MUTATION_TYPES.UPDATE_LOGIN_STATUS](state, isLogin: boolean) {
    state.isLogin = isLogin
  },
  [MUTATION_TYPES.SET_APIBASEURL](state, payload: UserInfo) {
    state.apiBaseUrl = payload;
  },
  [MUTATION_TYPES.SET_WS_SPEED](state, speed) {
    // console.log('speed', speed)
    state.wsSpeed = speed;
  },
  [MUTATION_TYPES.SET_SHAREPOSTERS](state, sharePosters) {
    state.sharePosters = sharePosters;
  },
};

export default mutations;
